 <template>
	<el-dialog title="对网点授权" top="1vh" width="1080px" :visible.sync="is_show_in_page">
		<el-form label-width="100px">


			<div class="big_tit" style="margin:0">网点信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="网点名称" style="width:388px">
					<el-input class="el_inner_width" v-model="outlets_info.name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="网点地址" style="width:588px">
					<el-input class="el_inner_width" v-model="outlets_addr_text" disabled></el-input>
				</el-form-item>
			</div>
			

			<div class="big_tit">授权方式</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="线路板块" style="width:440px">
					<div class="el_radio_group">
						<el-radio-group v-model="form.bl_status">
							<el-radio :label="1">不授权</el-radio>
							<el-radio :label="2">仅自有数据</el-radio>
							<el-radio :label="3">全部数据</el-radio>
						</el-radio-group>
					</div>
				</el-form-item>
				<el-form-item class="el_form_item" label="选项说明" style="width:536px">
					<el-input class="el_inner_width" v-model="explain.bl_status[form.bl_status]" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货源板块" style="width:440px">
					<div class="el_radio_group">
						<el-radio-group v-model="form.cargo_status">
							<el-radio :label="1">不授权</el-radio>
							<el-radio :label="2">仅自有数据</el-radio>
							<el-radio :label="3">全部数据</el-radio>
						</el-radio-group>
					</div>
				</el-form-item>
				<el-form-item class="el_form_item" label="选项说明" style="width:536px">
					<el-input class="el_inner_width" v-model="explain.cargo_status[form.cargo_status]" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运单板块" style="width:440px">
					<div class="el_radio_group">
						<el-radio-group v-model="form.tord_status">
							<el-radio :label="1">不授权</el-radio>
							<el-radio :label="2">仅自有数据</el-radio>
							<el-radio :label="3">全部数据</el-radio>
						</el-radio-group>
					</div>
				</el-form-item>
				<el-form-item class="el_form_item" label="选项说明" style="width:536px">
					<el-input class="el_inner_width" v-model="explain.tord_status[form.tord_status]" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="授权备注" style="width:947px">
					<el-input v-model="form.mark"></el-input>
				</el-form-item>
			</div>

			
			<div class="tord_real_time_add_bottom_btns">
				<el-button type="primary" @click="sub">提交</el-button>
			</div>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			grant_info:Object
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//省市县基础数据
				outlets_addr_text:'',

				//模式(1:添加,2:修改)
				mode:1,

				//网点信息
				outlets_info:{},

				//各个授权方式说明
				explain:{
					bl_status:{
						"1":"不对此网点授权操作线路板块",
						"2":"网点仅可以操作该网点添加的线路数据",
						"3":"网点可以操作全部线路数据",
					},
					cargo_status:{
						"1":"不对此网点授权操作货源板块",
						"2":"网点仅可以操作该网点添加的货源数据",
						"3":"网点可以操作全部货源数据",
					},
					tord_status:{
						"1":"不对此网点授权操作运单板块",
						"2":"网点仅可以操作该网点添加的运单数据",
						"3":"网点可以操作全部运单数据",
					},
				},

				//搜索条件
				form: {

					//线路版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
					bl_status:1,

					//货源版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
					cargo_status:1,

					//运单版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
					tord_status:1,

					//备注
					mark:'',
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//初始化
			init(){

				this.outlets_info=this.grant_info.outlets_info
				
				//网点地址
				this.outlets_addr_text=this.outlets_info.prov+"/"+this.outlets_info.city+"/"+this.outlets_info.county

				//读取网点授权
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'grant_list_by_shipper',
						outlets_num:this.outlets_info.outlets_num
					},
					callback:(data)=>{
						
						//取出数据
						let grant_info=data.list[0];

						//授权方式
						if(!grant_info){
							return;
						}

						//授权方式
						this.form.bl_status=parseInt(grant_info.bl_status)
						this.form.cargo_status=parseInt(grant_info.cargo_status)
						this.form.tord_status=parseInt(grant_info.tord_status)
						this.form.mark=grant_info.mark

						//授权id
						this.grant_id=grant_info.id

						//模式
						this.mode=2
					}
				});
			},

			//提交
			sub(){

				//线路板块
				if([1,2,3].indexOf(this.form.bl_status)==-1){
					this.$my.other.msg({
						type:'warning',
						str:"线路板块数据有误"
					});
					return;
				}

				//货源板块
				if([1,2,3].indexOf(this.form.cargo_status)==-1){
					this.$my.other.msg({
						type:'warning',
						str:"货源板块数据有误"
					});
					return;
				}

				//运单板块
				if([1,2,3].indexOf(this.form.tord_status)==-1){
					this.$my.other.msg({
						type:'warning',
						str:"运单板块数据有误"
					});
					return;
				}

				//模式
				if(this.mode==1){//添加
					var form={
						mod:'truck_business_outlets',
						ctr:'grant_add_by_shipper',
						outlets_num:this.outlets_info.outlets_num,
						bl_status:this.form.bl_status,
						cargo_status:this.form.cargo_status,
						tord_status:this.form.tord_status,
						mark:this.form.mark,
					};
				}else if(this.mode==2){//修改
					var form={
						mod:'truck_business_outlets',
						ctr:'grant_edit_by_shipper',
						id:this.grant_id,
						outlets_num:this.outlets_info.outlets_num,
						bl_status:this.form.bl_status,
						cargo_status:this.form.cargo_status,
						tord_status:this.form.tord_status,
						mark:this.form.mark,
					};
				}

				//调用接口
				this.$my.net.req({
					data:form,
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"添加成功"
						});

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit("closed");
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_radio_group{
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		text-align: center;
	}
	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.tord_real_time_add_bottom_btns{
		margin-top: 40px;
		text-align: center;
	}

</style>
	